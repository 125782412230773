<template>
  <v-container>
    <v-row justify="center" class="text-center pt-2 pb-4">
      <v-col class="mb-0" cols="12">
        <v-card elevation="8" class="pt-2 px-4 pb-8 rounded-xl">
          <v-card-title>
            {{ $t('headers.addressLookup') }} - {{ country }}
          </v-card-title>
          <v-row>
            <v-col class="mb-0" cols="12" sm="12" md="12">
              <v-autocomplete
                id="postalCode"
                clearable
                rounded
                outlined
                v-model="postalcode"
                :items="postalCodes"
                :loading="isLoading"
                :search-input.sync="search"
                hide-no-data
                :rules="[postalCodeRules]"
                :label="$t('labels.postalCode')"
                :placeholder="$t('labels.startTyping')"
                @blur="onBlurPostal()"
                :hint="hint"
              ></v-autocomplete>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-autocomplete
                id="line1"
                clearable
                rounded
                outlined
                :loading="isLoadingAddress"
                :search-input="searchLine1"
                v-model="line1"
                :items="addressesResultFiltered"
                :item-text="line1 ? 'line1' : (v) => generateAddress(v)"
                item-value="line1"
                hide-no-data
                hide-details
                :label="$t('labels.line1')"
                :placeholder="$t('labels.startTyping')"
                @focus="line1 = null; filterAddresses(searchLine1, 'line1')"
              ></v-autocomplete>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-autocomplete
                id="line2"
                clearable
                rounded
                outlined
                v-model="line2"
                :search-input.sync="searchLine2"
                :items="addressesLine2"
                hide-no-data
                hide-details
                :label="$t('labels.line2')"
                :placeholder="$t('labels.startTyping')"
                @blur="onBlurLine2()"
              ></v-autocomplete>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-autocomplete
                id="line3"
                clearable
                rounded
                outlined
                v-model="line3"
                :search-input.sync="searchLine3"
                :items="addressesLine3"
                hide-no-data
                hide-details
                :label="$t('labels.line3')"
                :placeholder="$t('labels.startTyping')"
              ></v-autocomplete>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-text-field
                v-model="line4"
                :label="$t('labels.line4')"
                outlined
                rounded
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-autocomplete
                id="townCity"
                clearable
                rounded
                outlined
                v-model="townCity"
                :items="addressesTownCity"
                hide-no-data
                hide-details
                :label="$t('labels.townCity')"
                :placeholder="$t('labels.startTyping')"
              ></v-autocomplete>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-text-field
                v-model="locality"
                :label="$t('labels.locality')"
                outlined
                rounded
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col class="mb-0" cols="12" sm="12" md="6">
        <p>Address: {{
            this.addressesResultFull.find((x) => x.postalCode === this.postalcode
              && x.line1 === this.line1
              && x.line2 === this.line2
              && x.townCity === this.townCity) || 'Not Valid'
          }}</p>
        <v-btn :disabled="!isValid" @click="webshopsLookup()">{{ isValid }}</v-btn>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AddressLookupUK',
  data: () => ({
    isLoadingAddress: false,
    isLoading: false,
    showNav: false,
    orderType: '',
    fullAddress: null,
    postalcode: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    townCity: '',
    locality: '',
    comment: '',
    err: '',
    warn: '',
    success: '',
    successPostal: '',
    deliveryCharges: null,
    distanceInMiles: 0,
    total: 0,
    itemsTotal: 0,
    validPostal: null,
    selectAddress: null,
    selectAddressAT: null,
    calcTimeout: null,
    latitude: null,
    longitude: null,
    resourceId: null,
    postalCodes: [],
    search: null,
    searchLine1: null,
    searchLine2: null,
    searchLine3: null,
    searchTownCity: null,
    searchAddress: null,
    hint: '',
    showAddressFields: false,
    addressesResultFull: [],
    addressesResultFiltered: [],
  }),
  computed: {
    ...mapGetters({
      lat: 'lat',
      lng: 'lng',
      dark: 'dark',
      country: 'country',
    }),
    postalCodeRules() {
      if (this.country && this.postalcode) {
        return this.testPostalCodeRules(this.country, this.postalcode);
      }
      return this.$t('errors.invalidZip');
    },
    addressesLine1() {
      return this.filterUniqueFields(this.addressesResultFull, 'line1');
    },
    addressesLine2() {
      return this.filterUniqueFields(this.addressesResultFiltered, 'line2');
    },
    addressesLine3() {
      return this.filterUniqueFields(this.addressesResultFiltered, 'line3');
    },
    addressesTownCity() {
      return this.filterUniqueFields(this.addressesResultFiltered, 'townCity');
    },
    isValid() {
      return !!this.addressesResultFull.find((x) => x.postalCode === this.postalcode
        && x.line1 === this.line1
        && x.townCity === this.townCity);
    },
  },
  watch: {
    fullAddress: {
      handler(val) {
        if (val) {
          this.line1 = val.line1;
          this.line2 = val.line2;
          this.line3 = val.line3;
          this.line4 = val.line4;
          this.townCity = val.townCity;
          this.locality = val.locality;
          this.latitude = val.latitude;
          this.longitude = val.longitude;
        }
      },
      deep: true,
    },
    search: {
      handler(val) {
        if (val) {
          this.search = this.search.trim();
        }
        // if (val && val !== this.postalcode) this.postalcode = '';
        this.searchLine1 = '';
        this.addressesResultFull = [];
        this.addressesResultFiltered = [];
        this.getPostalCodes(val);
      },
      deep: true,
    },
    searchLine1: {
      handler() {
        if (this.testPostalCodeRules(this.country, this.postalcode) === true
          && this.searchLine1) {
          this.filterAddresses(this.searchLine1, 'line1');
        }
      },
      deep: true,
    },
    postalcode() {
      this.postalcode = this.postalcode ? this.postalcode.trim() : '';
      if (this.testPostalCodeRules(this.country, this.postalcode) === true) {
        document.getElementById('postalCode')
          .blur();
        document.getElementById('line1')
          .click();
        document.getElementById('line1')
          .focus();
        this.line1 = null;
        this.searchLine1 = null;
        this.line2 = null;
        this.searchLine2 = null;
        this.townCity = null;
        this.line3 = null;
      }
    },
    line1() {
      if (!this.line1) {
        this.line2 = null;
        this.searchLine2 = null;
        this.townCity = null;
        this.line3 = null;
      }
      if (this.line1) {
        this.addressesResultFiltered = this.addressesResultFull.filter(
          (x) => x.line1 === this.line1,
        );
        if (this.addressesResultFiltered.length === 1) {
          setTimeout(() => {
            this.line2 = this.addressesResultFiltered[0].line2;
          }, 50);
        }
        if (this.filterUniqueFields(this.addressesResultFiltered, 'townCity').length === 1) {
          this.townCity = this.filterUniqueFields(this.addressesResultFiltered, 'townCity')[0];
        }
        document.getElementById('line1')
          .blur();
        document.getElementById('line2')
          .click();
        document.getElementById('line2')
          .focus();
      }
    },
    line2() {
      if (!this.line2) {
        this.line3 = null;
      }
      if (this.line2) {
        this.addressesResultFiltered = this.addressesResultFiltered.filter(
          (x) => x.line1 === this.line1 && x.line2 === this.line2,
        );
        if (this.addressesResultFiltered.length === 1) {
          setTimeout(() => {
            this.line3 = this.addressesResultFiltered[0].line3;
          }, 50);
        }
        if (this.filterUniqueFields(this.addressesResultFiltered, 'townCity').length === 1) {
          this.townCity = this.filterUniqueFields(this.addressesResultFiltered, 'townCity')[0];
        }
        document.getElementById('line2')
          .blur();
        document.getElementById('line3')
          .click();
        document.getElementById('line3')
          .focus();
      }
    },
    line3() {
      if (this.line2) {
        this.addressesResultFiltered = this.addressesResultFull.filter(
          (x) => x.line1 === this.line1 && x.line2 === this.line2 && x.line3 === this.line3,
        );
        if (this.filterUniqueFields(this.addressesResultFiltered, 'townCity').length === 1) {
          this.townCity = this.filterUniqueFields(this.addressesResultFiltered, 'townCity')[0];
        }
        document.getElementById('line3')
          .blur();
        document.getElementById('townCity')
          .click();
        document.getElementById('townCity')
          .focus();
      }
    },
  },
  methods: {
    filterAddresses(value, target) {
      if (this.addressesResultFull.length === 0) return;
      if (!value) {
        this.addressesResultFiltered = this.addressesResultFull;
        return;
      }
      if (target === 'line2' && this.line1 !== '') {
        this.addressesResultFiltered = this.addressesResultFull.filter((address) => {
          if (address[target] && address.line1 === this.line1) return address;
          return false;
        });
        return;
      }
      this.addressesResultFiltered = this.addressesResultFull.filter((address) => {
        if (address[target] && address[target].toLowerCase()
          .includes(value.toLowerCase()
            .trim())) {
          return address;
        }
        return false;
      });
    },
    generateAddress(input) {
      // if (this.line1) return input.line1;
      let address = '';
      const tempKeys = Object.keys(input);
      tempKeys.forEach((key, index) => {
        if (['latitude', 'longitude', 'country'].includes(key)) {
          address += '';
        } else if (input[key]) address += tempKeys.length !== index - 1 ? `, ${input[key].trim()}` : `${input[key].trim()}`;
      });
      address = address.substring(2, address.length);
      return address;
    },
    moveToHouseNo() {
      document.getElementById('line1')
        .blur();
      document.getElementById('line2')
        .click();
      document.getElementById('line2')
        .focus();
    },
    getPostalCodes(code) {
      if (!code) return;
      code.trim()
        .toUpperCase();
      this.hint = '';
      if (this.country === 'UK' && (code.length < 4 || code.length > 16)) return;
      if (this.country === 'AT' && code.length !== 4) return;
      this.isLoading = true;
      this.$http.get(`address/postalcodes/country/${this.country}?startswith=${code.trim()
        .toUpperCase()}`)
        .then((res) => {
          if (res.data && res.status === 200) {
            if (res.data.postalCodes.length === 0) {
              this.hint = this.$t('errors.noPostalFound');
              this.isLoading = false;
            } else {
              this.postalCodes = res.data.postalCodes;
              this.line1 = '';
              this.searchLine1 = '';
              if (res.data.postalCodes.length === 1) {
                this.postalcode = this.postalCodes[0];
              }
              this.isLoading = false;
            }
          }
        })
        .catch((err) => {
          this.err = err;
          this.isLoading = false;
        });
    },
    addressLookup(value) {
      if (!value) return;
      if (this.country === 'UK' && (value.length < 5 || value.length > 16)) return;
      if (this.country === 'AT' && value.length !== 4) return;
      if ((!value || !this.searchLine1 || (this.searchLine1 && this.searchLine1.length < 3)) && this.country === 'AT') return;
      this.isLoadingAddress = true;
      this.addressesResultFull = [];
      this.addressesResultFiltered = [];
      const payload = this.country === 'AT'
        ? `?postalCode=${value.toUpperCase()}&line1=${this.searchLine1}`
        : `?postalCode=${value.toUpperCase()}`;
      this.$http.get(`address/addresses/${this.country}${payload}`)
        .then((res) => {
          if (res.data && res.status === 200 && res.data.addresses) {
            this.hint = this.$t('success.postalSet');
            this.addressesResultFull = res.data.addresses || [];
            this.addressesResultFiltered = res.data.addresses || [];
            this.latitude = res.data.addresses[0].latitude;
            this.longitude = res.data.addresses[0].longitude;
            this.isLoadingAddress = false;
          } else {
            this.hint = res.data.statusText;
            this.isLoadingAddress = false;
          }
        })
        .catch((err) => {
          this.err = err;
          this.isLoadingAddress = false;
        });
    },
    webshopsLookup() {
      const address = this.addressesResultFiltered.find((x) => x.postalCode === this.postalcode
        && x.line1 === this.line1
        && x.line2 === this.line2
        && x.townCity === this.townCity);
      const payload = `?lat=${address.latitude}&lng=${address.longitude}&cuisine=Italian&maxDistance=50&distanceUnit=km`;
      this.$http.get(`searchwebshops/${payload}`)
        .then((res) => {
          if (res.data && res.status === 200) {
            console.log(res.data);
          } else {
            console.log('Error');
          }
        })
        .catch((err) => {
          this.err = err;
          this.isLoadingAddress = false;
        });
    },
    testPostalCodeRules(countryToCheck, zip) {
      if (!zip) return this.$t('errors.zipRequired');
      if (!countryToCheck) return this.$t('errors.countryRequired');
      if (countryToCheck === 'UK') {
        return zip.length > 5 && zip.length < 16;
      }
      if (countryToCheck === 'AT') {
        return zip.length === 4;
      }
      return this.$t('errors.countryUnsupported');
    },
    onBlurPostal() {
      if (this.testPostalCodeRules('UK', this.postalcode)) {
        this.addressLookup(this.postalcode);
      }
    },
    onBlurLine2() {
      if (this.searchLine2 && this.addressesLine2.includes(this.searchLine2.toUpperCase())) {
        this.line2 = this.searchLine2.toUpperCase();
      }
    },
    onBlurLine3() {
      if (this.searchLine3 && this.addressesLine3.includes(this.searchLine3.toUpperCase())) {
        this.line3 = this.searchLine3.toUpperCase();
      }
    },
    filterUniqueFields(addresses, property) {
      if (addresses && addresses.length > 0) {
        let filteredFieldList = addresses.map((address) => address[property]);
        // eslint-disable-next-line max-len
        filteredFieldList = filteredFieldList.filter((item, index) => filteredFieldList.indexOf(item) === index)
          .filter((x) => x !== undefined)
          .sort();
        return filteredFieldList;
      }
      return [];
    },
  },
  created() {
    console.log(this.$t('app_name'));
    console.log(this.country.toString());

    // const searchRestaurants = () => {
    // eslint-disable-next-line max-len
    // this.$http.get(`searchwebshops?lat=${position.coords.latitude}&lng=${position.coords.longitude}&maxDistance=5&distanceUnit=km`)
    //   .then((response) => {
    //     console.log(response.data);
    //   });
    // };

    // const showPosition = (position) => {
    //   console.log(position.coords);
    //   localStorage.GeolocationPosition = JSON.stringify({
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    //   searchRestaurants(position);
    // };

    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  },
};
</script>
